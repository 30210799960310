import React from "react";
import { Select } from "clutch/src/Select/Select.jsx";

import { GAME_MODE_RANKS, RANKS } from "@/game-csgo/constants.mjs";
import { getRankIconSmall } from "@/game-csgo/utils.mjs";

const FilterRanks = ({ onChange, selected }) => {
  return (
    <Select
      onChange={onChange}
      selected={selected}
      options={Object.keys(RANKS[GAME_MODE_RANKS.COMPETITIVE])
        .reverse()
        .filter(
          (k) =>
            RANKS[GAME_MODE_RANKS.COMPETITIVE][k].name !== "Unranked" &&
            RANKS[GAME_MODE_RANKS.COMPETITIVE][k].name !== "Expired",
        )
        .map((key) => {
          const rankObj = RANKS[GAME_MODE_RANKS.COMPETITIVE][key];
          return {
            value: key,
            text: [rankObj.i18nKey, rankObj.name],
            image: getRankIconSmall(rankObj.smallIcon),
          };
        })}
    />
  );
};

export default FilterRanks;
