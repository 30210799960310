import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { updateRoute } from "@/__main__/router.mjs";
import useStats from "@/game-cs2-marketing/utils/useStats.jsx";
import { GAME_MODE_TYPES } from "@/game-csgo/constants.mjs";
import { getSearchParams } from "@/game-csgo/csgo-fetch-stats-data.mjs";
import FilterModes from "@/game-csgo/FilterModes.jsx";
import FilterRanks from "@/game-csgo/FilterRanks.jsx";
import { getGameModeById } from "@/game-csgo/utils.mjs";
import DataTable, { DataTableLoading } from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const StyledFilterBar = styled(FilterBar)`
  display: flex;
  gap: var(--sp-2);
  align-items: center;
`;

const Table = () => css`
  .map-meta {
    display: flex;
    flex-direction: row;
    gap: var(--sp-3);
    align-items: center;
    img {
      width: var(--sp-16);
      height: var(--sp-8);
      border-radius: var(--br);
    }
  }
  .weapon-meta {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  .player-name {
    color: var(--shade0);
    white-space: break-spaces;
  }
`;

const TABS = {
  maps: {
    url: "/cs2/stats/maps",
    text: ["common:maps", "Maps"],
    Filters: ({
      searchText,
      setSearchText,
      updateSearchParam,
      t,
      mode,
      rank,
      isCompetitive,
    }) => {
      return (
        <StyledFilterBar hiddenItems={isCompetitive ? 2 : 1}>
          <TextInput
            defaultValue={searchText}
            placeholder={t("csgo:search.maps", "Search Maps")}
            onValueChange={setSearchText}
          />
          <FilterModes
            selected={mode}
            onChange={(v) => updateSearchParam("mode", v)}
            aggregateStats
          />
          {isCompetitive ? (
            <FilterRanks
              selected={rank}
              onChange={(v) => updateSearchParam("rank", v)}
              inputStyle
            />
          ) : null}
        </StyledFilterBar>
      );
    },
    meta: {
      title: ["common:navigation.cs2MapStatistics", "CS2 Map Stats"],
      description: [
        "",
        "Dive into our CS2 Map Stats Tracker for detailed data on win rates, total matches, and other performance metrics. Sourced from players globally, use our data driven insights to enhance your map strategies.",
      ],
    },
  },
  weapons: {
    url: "/cs2/stats/weapons",
    text: ["common:weapons", "Weapons"],
    Filters: ({
      searchText,
      setSearchText,
      updateSearchParam,
      t,
      mode,
      rank,
      isCompetitive,
    }) => (
      <StyledFilterBar hiddenItems={isCompetitive ? 2 : 1}>
        <TextInput
          defaultValue={searchText}
          placeholder={t("csgo:search.weapons", "Search Weapons")}
          onValueChange={setSearchText}
        />
        <FilterModes
          selected={mode}
          onChange={(v) => updateSearchParam("mode", v)}
          aggregateStats
        />
        {isCompetitive ? (
          <FilterRanks
            selected={rank}
            onChange={(v) => updateSearchParam("rank", v)}
            inputStyle
          />
        ) : null}
      </StyledFilterBar>
    ),
    meta: {
      title: ["common:navigation.cs2WeaponStatistics", "CS2 Gun Stats"],
      description: [
        "",
        "Explore our CS2 Weapon Stats Tracker for a wide range of statistics about all in-game weapons. Sourced from a global player base, our data-driven insights offer a unique perspective on weapon performance.",
      ],
    },
  },
};

function Statistics() {
  const {
    currentPath,
    searchParams,
    parameters: [tab],
  } = useRoute();
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const { rank, gameModeId } = getSearchParams(searchParams);
  const isCompetitive =
    getGameModeById(gameModeId).type === GAME_MODE_TYPES.COMPETITIVE;
  const updateSearchParam = useCallback(
    (key, value) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
      updateRoute(currentPath, searchParams);
    },
    [searchParams, currentPath],
  );

  const Filters = TABS[tab].Filters;

  const { cols, rows } = useStats({
    tab,
    searchParams,
  });

  const isLoading = !rows;

  if (isLoading) {
    return (
      <PageContainer>
        <PageHeader
          title={TABS[tab].meta.title}
          links={Object.values(TABS).map(({ url, text }) => {
            return {
              url,
              text: text,
            };
          })}
        />
        <Filters
          searchText={searchText}
          setSearchText={setSearchText}
          updateSearchParam={updateSearchParam}
          t={t}
          mode={gameModeId}
          rank={rank}
          isCompetitive={isCompetitive}
        />
        <DataTableLoading height={1600} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader
        title={TABS[tab].meta.title}
        links={Object.values(TABS).map(({ url, text }) => {
          return {
            url,
            text: text,
          };
        })}
      />
      <Filters
        searchText={searchText}
        setSearchText={setSearchText}
        updateSearchParam={updateSearchParam}
        t={t}
        mode={gameModeId}
        rank={rank}
        isCompetitive={isCompetitive}
      />
      {tab === "maps" ? (
        <DataTable
          cols={cols}
          rows={rows}
          indexCol
          indexColTitle={"#"}
          className={Table()}
          sortCol={1}
          sortColTiebreak={1}
          sortDir="DESC"
          searchText={searchText}
          searchCol={0}
        />
      ) : null}
      {tab === "weapons" ? (
        <DataTable
          cols={cols}
          rows={rows}
          indexCol
          indexColTitle={"#"}
          className={Table()}
          sortCol={5}
          sortColTiebreak={1}
          sortDir="DESC"
          searchText={searchText}
          searchCol={0}
        />
      ) : null}
    </PageContainer>
  );
}

export function meta([tab]) {
  return {
    title: TABS[tab].meta.title,
    description: TABS[tab].meta.description,
    subtitle: true,
  };
}

export default Statistics;
